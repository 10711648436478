import React from 'react';
import { graphql } from 'gatsby';

// Components
import HeroSpace from '../components/HeroSpace/HeroSpace';
import ContactForm from '../components/ContactForm/ContactForm';
import styles from './contact.module.scss';
import { getParameterByName } from '../utils/location';

const Contact = ({ data, location }) => {
  const type = getParameterByName('type', location.search);
  return (
    <div>
      <HeroSpace
        title="Contact Us"
        subtitle="Get in touch to find out more today."
        image={{
          fluid: data.heroImage.fluid,
          alt:
            'Line drawing of a laptop with various communication methods surrounding it.',
          className: styles.heroGraphic,
        }}
        extraChildren={<ContactForm locationType={type} />}
      />
    </div>
  );
};

export default Contact;
export const pageQuery = graphql`
  query contactHeroImage {
    heroImage: imageSharp(
      fluid: { originalName: { regex: "/contact-heroImage[.]png/" } }
    ) {
      fluid(maxWidth: 1330, quality: 85, traceSVG: { color: "#ffffff" }) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
