import React from 'react';

import WrappedImage from '../WrappedImage/WrappedImage';

import styles from './ContactForm.module.scss';

import {
  combine,
  flex,
  bg,
  txt,
  border,
  css,
  palette,
} from '../../style/utils';
import nameIcon from './name.png';
import phoneIcon from './phone.png';
import emailIcon from './email.png';
import locationIcon from './location.png';
import contactIcon from './contact.png';
import mailIcon from './mail.png';
import { Link } from 'gatsby';

const purpleOutline = css({
  transition: 'outline 0.1s ease-in-out',
  ':focus': {
    outlineColor: palette.purple,
  },
});

const getPlaceholder = type => {
  switch (type) {
    case 'general':
      return 'Your message*';
    case 'trial':
      return 'Your requirement*\nPlease tell us a bit about how you would like to use Flare.';
    case 'demo':
      return 'What time(s) would be most convenient?*\nPlease also tell us a bit about how you would like to use Flare.';
    default:
      return 'Your message*';
  }
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    // Initial render, always use the location type
    this.state = {
      placeholder: getPlaceholder(this.props.locationType),
      type: this.props.locationType,
      initialType: this.props.locationType,
      submitted: false,
    };
  }

  updateState(type) {
    this.setState({
      placeholder: getPlaceholder(type),
      type: type,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const [honeypot, type, name, phone, email, requirement] = Array.from(
      e.target
    ).map(t => t.value);

    if (honeypot.length > 0) return;

    fetch(
      // eslint-disable-next-line no-undef
      `${process.env.GATSBY_FUNCTIONS_URL_BASE}contact/slack?` +
        new URLSearchParams({ type, name, phone, email, requirement })
    );

    this.setState({
      submitted: true,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.locationType !== state.initialType) {
      // location has changed.
      return {
        placeholder: getPlaceholder(props.locationType),
        type: props.locationType,
        initialType: props.locationType,
      };
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className={combine(flex.row, styles.container)}>
        <section
          className={combine(flex.col, flex[2], bg.white, styles.relative)}
        >
          {this.state.submitted && (
            <div className={combine(styles.completionBanner)}>
              <div className={combine(flex.col)}>
                <h6 className={combine(txt.uppercase, txt.bold)}>
                  Thanks for getting in touch
                </h6>
                <p className={combine(styles.paragraphText, txt.center)}>
                  We&apos;ll contact you soon to discuss your requirement.
                </p>
              </div>
            </div>
          )}
          <h6
            className={combine(
              styles.header,
              txt.purple,
              txt.uppercase,
              txt.bold
            )}
          >
            Send us a message
          </h6>
          <form
            data-netlify={true}
            // method="post"
            name="contact"
            netlify-honeypot="bot-field"
            onSubmit={this.onSubmit.bind(this)}
            className={combine(flex.col, styles.form)}
          >
            <p className={styles.honeypot}>
              <label>
                Don’t fill this out if you&nbsp;re human:{' '}
                <input name="bot-field" />
              </label>
            </p>

            <div className={styles.typeWrapper}>
              <select
                required
                name="type"
                className={combine(
                  bg.white,
                  border.footerHeaderGrey,
                  purpleOutline
                )}
                value={
                  this.state.type || '' // defaultValue={this.type || ''}
                }
                onChange={e => this.updateState(e.target.value)}
              >
                <option value="" disabled>
                  How can we help?
                </option>
                <option value="general">I have a general enquiry</option>
                <option value="demo">I&apos;d like to request a demo</option>
                <option value="trial">I&apos;d like to request a trial</option>
              </select>
            </div>

            <span className={styles.inputWrapper}>
              <input
                required="true"
                aria-label="Full name"
                name="name"
                className={combine(styles.input, purpleOutline)}
                type="text"
                placeholder="Full name*"
                autoComplete="name"
              />
              <img className={styles.inputIcon} src={nameIcon} alt="" />
            </span>
            <span className={styles.inputWrapper}>
              <input
                aria-label="Phone number"
                name="phone"
                className={combine(styles.input, purpleOutline)}
                type="tel"
                placeholder="Phone number"
              />
              <img className={styles.inputIcon} src={phoneIcon} alt="" />
            </span>
            <span className={styles.inputWrapper}>
              <input
                required
                aria-label="Email address"
                name="email"
                className={combine(styles.input, purpleOutline)}
                type="email"
                placeholder="Email*"
              />
              <img
                className={combine(styles.inputIcon, styles.inputIcon__email)}
                src={emailIcon}
                alt=""
              />
            </span>
            <textarea
              required
              aria-label="Your requirement"
              name="requirement"
              className={combine(styles.input, purpleOutline)}
              rows="4"
              placeholder={this.state.placeholder}
            />
            <label htmlFor="ppAccept" className={combine(flex.row, flex.aic)}>
              <input
                className={styles.privacyCheckbox}
                type="checkbox"
                aria-label="I have read and understood the terms of the Privacy Policy"
                id="ppAccept"
                required
              />
              <span>
                I have read and understood the terms of the
                <Link to="/privacy" className={txt.blue}>
                  &nbsp;privacy policy&nbsp;
                </Link>
                and consent to the processing of my data for non-marketing
                purposes only.
              </span>
            </label>
            <button
              aria-label="Submit"
              type="submit"
              className={combine(
                purpleOutline,
                styles.sendButton,
                txt.purple,
                border.purple,
                bg.white
              )}
            >
              Send Message
            </button>
          </form>
        </section>
        <section className={combine(flex.col, flex[1], bg.purple)}>
          <h6
            className={combine(
              styles.header,
              txt.white,
              txt.uppercase,
              txt.bold
            )}
          >
            Global Headquarters
          </h6>
          <div className={combine(flex.row)}>
            <WrappedImage
              src={locationIcon}
              wrapperClass={combine(styles.locationIcon, flex.autoOnly)}
              alt=""
            />
            <p className={combine(styles.companyInfo, txt.white)}>
              Boundary House (Hubspace)
              <br />
              Cricket Field Road
              <br />
              Uxbridge
              <br />
              UB8 1QG
              <br />
              United Kingdom
              <br />
            </p>
          </div>
          <div className={combine(flex.row)}>
            <WrappedImage
              src={contactIcon}
              wrapperClass={combine(styles.contactIcon, flex.autoOnly)}
              alt=""
            />
            <p className={combine(styles.companyInfo, txt.white)}>
              +44 (0) 330 088 0504
            </p>
          </div>
          <div className={combine(flex.row)}>
            <WrappedImage
              src={mailIcon}
              wrapperClass={combine(styles.mailIcon, flex.autoOnly)}
              alt=""
            />
            <p className={combine(styles.companyInfo, txt.white)}>
              info@pixelnebula.com
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default ContactForm;
